import React from 'react';
import './App.css';

function App() {
    return (
        <>
            {/*<header id="particles-js" className="container-fluid afacad-flux black-bg">*/}
            <header className="container-fluid afacad-flux black-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-7 col-xl-8 col-xxl-9">
                            <h1>Orosz József Ceremóniamester</h1>
                            <h2>Esküvőszervezési szolgáltatás</h2>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-5 col-xl-4 col-xxl-3 orosz-jozsef-ceremoniamester">
                            <img src="/img/orosz-jozsef-ceremoniamester.jpg" alt="orosz-jozsef-ceremoniamester"/>
                            &nbsp;
                        </div>
                    </div>
                    <div className="ripple-background">
                        <div className="circle xxlarge shade1"></div>
                        <div className="circle xlarge shade2"></div>
                        <div className="circle large shade3"></div>
                        <div className="circle mediun shade4"></div>
                        <div className="circle small shade5"></div>
                    </div>
                </div>
            </header>

            <section className="container afacad-flux first-section">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Kedves Jegyespár!</h1>
                        <h2>
                            Megérdemlitek, hogy rolatok szóljon ez a nap!
                            <br/>&nbsp;
                        </h2>
                        <p className="text-indent-justify">
                            Esküvőt koordinálni szinte bárki tud, de stresszmentes esküvőhöz profi kell!


                            Legyen szó kissebb vagy nagyobb esküvőről, általában felmerül, hogy milyen
                            ceremóniamesterre
                            van szükség egyáltalán:


                            Fontos, hogy kézben tudja tartani a programot, koordinálja mind a násznépet, mind a
                            szolgáltatókat és a forgatókönyvet megközelítőleg tartsa.


                            Oldja meg a felmerülő problémákat és ne titeket stresszeljen vele.

                            Legyen figyelmes és határozott, ahhoz hogy mindenki kövesse amit mondd, de közben a nagyi is
                            épp annyira legyen tőle elolvadva, mint amennyire a gyerekek tudnak vele beszélni.


                            Legyen humoros, de ne legyen színpadias.

                            Rólatok szóljon az esküvő és épp annyit szerepeljen amire szükség van, de még nem sok.


                            <br/>
                            <br/>
                            A legfontosabbak:
                        </p>
                        <ul>
                            <li>A programokat hozzátok igazítom, mivel ez a nap rólatok szól!</li>
                            <li>Elegánsan és jó humorral koordinálom az esküvőtöket!</li>
                            <li>Ti maradtok a középpontban, mivel csak annyi és olyan program/játék lesz, amennyit
                                megbeszélünk előre.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="container-fluid bg-gray">
                <div className="container afacad-flux about-section">
                    <div className="row">
                        <div className="col-12">
                            <h1>Mitől jó egy ceremóniamester?</h1>
                        </div>
                        <div className="col-12 col-lg-7">
                            <p className="text-indent-justify">
                                Mi a legszükségesebb dolog ahhoz, hogy profin le lehessen vezényelni egy lakodalmat?

                                <br/> Erre egyetlen szó a legjobb válasz. Ami kell egy esküvőhöz, az leginkább a
                                FIGYELEM.

                                <br/>
                                <br/> Egy jó esküvői mulatsághoz nem árt feltenni néhány kérdést és aztán gondosan
                                meghallgatni azokata bizonyos válaszokat.

                                <br/>
                                <br/> Meg kell ismerni a pár kívánságait. Tudni kell, hogy egy visszafogottabb,
                                szolidabb, vagy egyharsányabb, viccesebb ceremóniamesterre vágynak. Akarnak sok vicces
                                játékot, vagy inkábbszeretnének ebből kimaradni, és inkább egy nagyot bulizni. Tudni
                                kell, milyen összetételű lesz a násznép, kb. milyen százalékban idősebbek,
                                fiatalabbak. Jó tudni, mennyire ismerik egymást a vőlegény és a menyasszony
                                rokonai/barátai. Ha kevésbéismerik egymást, érdemes törekedni rá, hogy olyan
                                szituációkat teremtsünk (ülésrendben, játékokban) hogy ezek az emberek össze tudjanak
                                ismerkedni.

                                <br/>
                                <br/>Tudni kell, mikor kell beszélni, és ami néha még fontosabb, tudni kell, mikor kell
                                hallgatni. Tudnikell megoldani az apró problémákat, mondani pár vicces mondatot akkor,
                                amikor a menyasszonymég nem öltözött át, de már kezdődne az újasszonytánc, de bölcsen
                                csöndben maradni, nembeszúrni még egy játékot, mikor a násznép már éppen felszabadultan
                                ropja a táncot.

                                <br/>
                                <br/>Minden lagzi egy picit más, de mégis egy picit ugyanolyan. Emberekből áll, akik meg
                                akarják ismerni egymást, és akik jól akarják magukat együtt érezni. Működjünk együtt, és
                                én segítek ebben! </p>
                        </div>
                        <div className="col-12 col-lg-5 right-section d-none d-lg-block">
                            <img src="/img/orosz-jozsef-ceremoniamester-2.jpg"
                                 alt="orosz-jozsef-ceremoniamester"
                                 className="sm"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container afacad-flux about-section">
                <div className="row mb-66">
                    <div className="col-12">
                        <h1>Referenciák</h1>
                    </div>
                    <div className="col-12 col-lg-7 right-section">
                        <img src="/img/ref/6587.jpg" alt="értékelés-1"/>
                    </div>
                    <div className="col-12 col-lg-5">
                        <p className="text-indent-justify">
                            Józsinál jobb ceremóniamestert el sem képzelhettünk volna. Rengeteg tanáccsal, remek
                            ötletekkel látott el minket az esküvőnk előtt, ami nagy segítség volt a számunkra. A
                            kapcsolattartás folyamatos volt, jó érzés abban a káoszban tudni, hogy van valaki, aki
                            biztosan kézben tartja majd a dolgokat a nagy napon. Az esküvőnk napja remek hangulatban
                            telt, ahol rögtönöznie kellett, azt is tökéletesen megoldotta. A kreatív fotózásunk alatt
                            sem hagyta, hogy ellaposodjon a násznép hangulata, táncversennyel rázta fel őket, amit azóta
                            is emlegetnek, olyan jó volt! Sok esküvőn jártunk de egyiken sem volt ilyen figyelmességgel
                            a ceremóniamester, nem álltak elő ilyen jó (akár spontán) ötletekkel, mint Józsi.
                            Csillagos ötös!
                            Köszönjük szépen, hogy velünk voltál!
                        </p>
                    </div>
                </div>
                <div className="row mb-66">
                    <div className="col-12 col-lg-7 right-section d-sm-flex d-md-flex d-lg-none d-xl-none d-xxl-none">
                        <img src="/img/ref/anna-gyuri-c.jpg" alt="értékelés-2"/>
                    </div>
                    <div className="col-12 col-lg-5">
                        <p className="text-indent-justify">
                            Egy közeli barátunk ajánlotta Józsit. Az első perctől kezdve megvolt az összhang. Már a
                            telefonos egyeztetés során eldöntöttük, hogy Ő lesz a mi emberünk, a személyes
                            egyeztetés csak még jobban megerősítette ezt az érzést! Nem volt sok időnk az esküvőnk
                            szervezésére, mindent egy hónap alatt kellett eldöntenünk, így fontosnak tartottuk, hogy
                            olyan embert találjunk, aki ilyen rövid időn belül megismer minket és tökéletesen
                            lekoordinálja a nagy napunkat. Józsi ebben tökéletes választás volt, mintha a
                            családtagunk lett volna, mindent kézben tartott, nagyon jó játékokat és megoldásokat
                            hozott, amik örök emlékek maradnak. Minden párnak ajánljuk ezt az Úriembert, mert Ő
                            tényleg a tökéletesre törekszik, mert vele nem voltak családi drámák, mindent kézben
                            tartott. Nagyon szuper ember, tele jó gondolatokkal és odafigyeléssel, látszik, hogy
                            imádja a munkáját, ezért mindenki imádta Őt is! Zárószóként csak annyit, hogy az
                            esküvőnk volt a kezdet, de a barátságunk reméljük örök marad!
                            <br/>Anna & Gyuri
                        </p>
                    </div>
                    <div className="col-12 col-lg-7 right-section d-none d-lg-flex">
                        <img src="/img/ref/anna-gyuri-c.jpg" alt="értékelés-2"/>
                    </div>
                </div>
                <div className="row mb-66">
                    <div className="col-12 col-lg-7 right-section">
                        <img src="/img/ref/5476.jpg" alt="értékelés-1"/>
                    </div>
                    <div className="col-12 col-lg-5">
                        <p className="text-indent-justify">
                            Köszönjük szépen Orosz József-nek, a legjobb Ceremóniamesternek, hogy bearanyozta a mi Nagy
                            Napunkat.
                            Szuper hangulatot teremtettél a kis csínyjeiddel.
                            Sosem fogjuk elfelejteni. (Reméljük, hogy te sem)
                            Fantasztikus barát vagy Fantasztikus Ceremóniamester.
                            Mindenkinek csak téged fogunk ajánlani!!!
                            <br/>Ildikó & Enrico és Lili 🥰
                        </p>
                    </div>
                </div>
                <div className="row mb-66">
                    <div className="col-12 col-lg-5">
                        <p className="text-indent-justify">
                            Drága Barátunk, Józsi!
                            <br/>Köszönjük, hogy elfogadtad a felkérésünket, tökéletesen koordináltad a mi Nagy
                            Napunkat. Életünk eddigi legszebb napja volt, ezért Neked is hálával tartozunk. Profin végig
                            nyomtad, nekünk aggodalomra okunk nem volt. Mi csak sodródtunk az árral, élveztük az esemény
                            minden percét, amit a Te szervezésed nyújtott, úgy a násznépnek, úgy nekünk.
                            Az első közös megbeszélés után mind a ketten éreztük Jocival, hogy a lehető legjobban
                            döntöttünk, amikor Téged kértünk fel. Ezt az érzést megerősítették a többszöri
                            telefonálások, az üzenet váltások. Mindig mindenben a segítségünk voltál.
                            “Ahogy szeretnétek, hisz ez a ti napotok”, a mondat, amivel mindig bíztattad a
                            gondolatainkat, ezzel azt sugallva hogy igenis fontos neked, hogy nekünk milyen
                            elképzeléseink vannak. Ha úgy volt a forgatókönyvet is átírtad, mert mi éppen mást
                            gondoltunk ki😃 Te nem csak egy ceremóniamester vagy, Te a nagybetűs ceremóniamester vagy! És
                            mindezeken túl köszönjük hogy mára már a Barátunknak mondhatunk Téged.
                            <br/>Évi&Joci
                        </p>
                    </div>
                    <div className="col-12 col-lg-7 right-section">
                        <img src="/img/ref/evi_and_joci.jpeg" alt="értékelés-1"/>
                    </div>
                </div>
            </section>
            <section className="container-fluid afacad-flux about-section bg-gray">
                <div className="container afacad-flux about-section bg-gray">
                    <div className="row">
                        <div className="col-12">
                            <h1>Szolgáltatások és árak</h1>
                        </div>
                        <div className="col-12">
                            <ul>
                                <li>
                                    Előzetes konzultáció: Személyes találkozó vagy online konzultáció a
                                    tervekről és az
                                    elvárásaitokról.
                                </li>
                                <li>
                                    Rendezvény napja:
                                    <ul>
                                        <li> Érkezés a helyszínre a készülődési időszakban.</li>
                                        <li> A vendégek fogadása és eligazítása.</li>
                                        <li> Az esemény zökkenőmentes irányítása és koordinálása.</li>
                                        <li> Beszédek, toasztok és egyéb ceremóniák szervezése és
                                            koordinálása.
                                        </li>
                                        <li>
                                            A rendezvény hangulatának fenntartása és a vendégekkel való
                                            interakció.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Extra Szolgáltatások (opcionális):
                                    <ul>
                                        <li> Egyedi esküvői szkript készítése.</li>
                                        <li> Részvétel a tervezési folyamatban és tanácsadás.</li>
                                        <li> Segítség a beszédek és toasztok előkészítésében.</li>
                                    </ul>
                                </li>
                                <li>
                                    Az esküvői ceremóniamester szolgáltatásaim alapára 200.000.-Ft.
                                    <ul>
                                        <li>
                                            Tartalmazza az előzetes konzultációt,
                                            és a rendezvény napján nyújtott szolgáltatásokat.
                                        </li>
                                        <li>
                                            Az alapár Budapest területére vonatkozik.
                                        </li>
                                        <li>
                                            Az útiköltség Budapest határán kívül 100ft/km.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid">
                <div className="container afacad-flux about-section">
                    <div className="row">
                        <div className="col-12">
                            <h1>Kapcsolat</h1>
                        </div>
                        <div className="col-12 col-lg-5 mb-66">
                            <div className="fb-page"
                                 data-href="https://www.facebook.com/profile.php?id=61551051665219"
                                 data-tabs="" data-width="" data-height="" data-small-header="false"
                                 data-adapt-container-width="true" data-hide-cover="false"
                                 data-show-facepile="false">
                                <blockquote cite="https://www.facebook.com/profile.php?id=61551051665219"
                                            className="fb-xfbml-parse-ignore"><a
                                    href="https://www.facebook.com/profile.php?id=61551051665219">Orosz József
                                    Ceremóniamester</a></blockquote>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <p className="text-indent-justify">
                                Kérlek, keressetek bizalommal a további részletek és az árajánlat pontosítása
                                érdekében.
                                <br/> Szívesen állok rendelkezésetekre, hogy az esküvőtök egy felejthetetlen
                                élménnyé váljon.
                                <br/>
                                <br/>
                                <a href="tel:+36309858571">+36 30 985 8571</a>
                                <br/>
                                <a href="mailto:oroszjozsef.cm@gmail.com">oroszjozsef.cm@gmail.com</a>
                                <br/>
                                <br/>Tisztelettel, Orosz József CM
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default App;
